import api, {
    CommitmentType, LoanPricingResult, LoanPricingResultDetail, LockPeriod,
    RegistrationType, commitmentTypeDisplay, registrationTypeDisplay
} from '@api';
import { InfoOutlined } from '@mui/icons-material';
import { Alert, Button, Typography } from '@mui/material';
import { PaginatedResponse } from '@tsp-ui/core';
import {
    ButtonBar, DateTypography, IconTypography, LabelGroup, LabeledValue, Loader
} from '@tsp-ui/core/components';
import { useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils';
import { LoanCard } from '@views/loans/components/LoanCard';
import { addDays, parseISO } from 'date-fns';
import {
    Dispatch, SetStateAction, useCallback, useEffect, useState
} from 'react';

import styles from './LoanPricingResultCard.module.scss';
import PricingExpiredButton from './PricingExpiredButton';
import PricingResultIneligibleProductsTable from './PricingResultIneligibleProductsTable';
import PricingResultProductsTable from './PricingResultProductsTable';
import RateSheetLink from './RateSheetLink';


interface LoanPricingResultCardProps {
    className?: string;
    loanPricingResult: LoanPricingResult;
    updateLoanPricingResults: Dispatch<SetStateAction<PaginatedResponse<LoanPricingResult>>>;
    refreshPricingResults(): Promise<void>;
    updateFloatedLoans(): void;
    index: number;
    currentNoteRates: number[];
}

// TODO post-demo we need a LoanResultCard as a base component for
// LoanPricingResultCard, LoanPipelineResultCard, and LoanDashboardResultCard
export default function LoanPricingResultCard(props: LoanPricingResultCardProps) {
    const {
        loanPricingResult, updateLoanPricingResults, updateFloatedLoans, currentNoteRates, refreshPricingResults
    } = props;
    const [ selectedNoteRate, setSelectedNoteRate ] = useState<number | null>(null);

    const {
        expirationDate, loanId, rateSheetId, pricedDate, interestRate, id
    } = loanPricingResult;

    useEffect(() => {
        if (interestRate !== undefined && selectedNoteRate === null) {
            setSelectedNoteRate(interestRate);
        }
    }, [ interestRate, selectedNoteRate ]);

    const isExpired = parseISO(expirationDate) < new Date();

    return (
        <LoanCard
            {...props}
            isPendingLoan
            selectedNoteRate={selectedNoteRate}
            setSelectedNoteRate={setSelectedNoteRate}
            currentNoteRates={currentNoteRates}
            isProductAndPricingPage
            expandedContent={(
                <LoanPricingResultDetails
                    loanId={loanId}
                    pricingResultId={id}
                    interestRate={interestRate}
                    updateLoanPricingResults={updateLoanPricingResults}
                    updateFloatedLoans={updateFloatedLoans}
                    refreshPricingResults={refreshPricingResults}
                    isExpired={isExpired}
                    selectedNoteRate={selectedNoteRate}
                />
            )}
            additionalDetails={(
                <div className={styles.pricingDetails}>
                    <LabelGroup>
                        <LabeledValue
                            label="Rate sheet ID:"
                            value={<RateSheetLink rateSheetId={rateSheetId} />}
                        />

                        <LabeledValue
                            label="Priced at:"
                            value={(
                                <DateTypography
                                    time
                                    date={pricedDate}
                                    component="span"
                                    variant="body2"
                                />
                            )}
                        />
                    </LabelGroup>

                    {isExpired && (
                        <PricingExpiredButton
                            expirationDate={expirationDate}
                            loanId={loanId}
                            updateLoanPricingResults={updateLoanPricingResults}
                            updateFloatedLoans={updateFloatedLoans}
                        />
                    )}
                </div>
            )}
        />
    );
}

interface LoanPricingResultDetailsProps {
    loanId: string;
    pricingResultId: string;
    interestRate: number;
    updateLoanPricingResults: Dispatch<SetStateAction<PaginatedResponse<LoanPricingResult>>> | undefined;
    updateFloatedLoans: (() => void) | undefined;
    refreshPricingResults?(): Promise<void>;
    isExpired: boolean;
    selectedNoteRate: number | null;
}

export function LoanPricingResultDetails({
    loanId, pricingResultId, interestRate,
    updateLoanPricingResults, updateFloatedLoans, isExpired, selectedNoteRate, refreshPricingResults
}: LoanPricingResultDetailsProps) {
    const pageMessage = usePageMessage();
    const { id: clientId, customerId } = useGetCurrentAccount();

    const [ showIneligibleProducts, setShowIneligibleProducts ] = useState(false);
    const [ loanPricingResultDetail, setLoanPricingResultDetail ] = useState<LoanPricingResultDetail>();
    const [ commitmentType, setCommitmentType ] = useState(CommitmentType.BEST_EFFORT);
    const [ lockPeriod, setLockPeriod ] = useState<LockPeriod>();
    const [ showError, setShowError ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const lockExpirationDate = addDays(new Date(), lockPeriod || 0).toISOString();

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            const pricingDetail = await api.pricing.getPricingResultDetail(clientId, loanId, customerId);

            setLoanPricingResultDetail(pricingDetail);
            setLockPeriod(pricingDetail.defaultLockPeriod);

            if (selectedNoteRate !== null) {
                const updatedPricingDetail = await api.pricing.getNoteRatePricingResults(clientId, loanId, customerId, {
                    noteRate: selectedNoteRate
                });
                setLoanPricingResultDetail(updatedPricingDetail);
            }
        } catch (error) {
            setShowError(true);
        }

        setLoading(false);
    }, [
        clientId, customerId, loanId, selectedNoteRate, setLoading
    ]));

    const handleButtonBarChange = useCallback((newValue: string) => {
        if (newValue) {
            setLockPeriod(parseInt(newValue));
        }
    }, [ ]);

    const products = loanPricingResultDetail?.products.filter(
        product => product.commitmentType === commitmentType && product.lockPeriod === lockPeriod
    ) || [];

    const lockPeriodOptions = loanPricingResultDetail?.products.reduce((previousValue, currentValue) => {
        if (currentValue.commitmentType === commitmentType) {
            previousValue[`${currentValue.lockPeriod}`] = currentValue.lockPeriod;
        }

        return previousValue;
    }, {} as {[key: string]: LockPeriod}) || {};

    async function registerLoan(productID: string, registrationType: RegistrationType, noteRate?: number) {
        if (!lockPeriod) {
            return;
        }

        setLoading(true);

        try {
            // TODO make this return the Loan
            await api.loans.registerLoan(clientId, loanId, {
                pricingResultId,
                registrationType,
                productId: productID,
                noteRate
            }, customerId);

            await refreshPricingResults?.();

            updateLoanPricingResults?.((loanPricingResults) => ({
                ...loanPricingResults,
                totalRecords: loanPricingResults.totalRecords - 1,
                data: loanPricingResults.data.filter(pricingResult => (
                    pricingResult.loanId !== loanId
                ))
            }));

            if (registrationType === RegistrationType.FLOAT) {
                updateFloatedLoans?.();
            }

            setLoading(false); // set loading before component unmounts

            pageMessage.success(`Loan ${registrationTypeDisplay[registrationType]}`);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while registering the loan', error);
            setLoading(false);
        }
    }

    const currentNoteRate = selectedNoteRate !== null ? selectedNoteRate : interestRate;

    // Can't use the loading prop to ExpandableCard because it unmounts LoanPricingResultDetails,
    // resulting in an infinite fetch loop
    return loading ? (
        <Loader
            className={styles.initialLoader}
            loading={loading}
        />
    ) : showError ? (
        <Alert severity="warning">
            An error occurred while fetching product destails
        </Alert>
    ) : (!lockPeriod || !loanPricingResultDetail) ? null : (
        <>
            <div className={styles.pricingControls}>
                <ButtonBar
                    label="Lock Period"
                    options={lockPeriodOptions}
                    onValueChange={handleButtonBarChange}
                    defaultValue={lockPeriod.toString()}
                    className={styles.buttonBar}
                />

                <ButtonBar
                    label="Commitment Type"
                    options={commitmentTypeDisplay}
                    onValueChange={newValue => setCommitmentType(newValue as CommitmentType)}
                    defaultValue={commitmentType}
                    className={styles.buttonBar}
                />

                <IconTypography
                    variant="body2"
                    fontWeight={400}
                    className={styles.lockInfo}
                    icon={(
                        <InfoOutlined
                            fontSize="small"
                            color="primary"
                        />
                    )}
                >
                    <span>
                        If locked today, lock will expire on&nbsp;

                        <DateTypography
                            date={lockExpirationDate}
                            component="span"
                            variant="inherit"
                        />
                    </span>
                </IconTypography>
            </div>

            {products.length ? (
                <PricingResultProductsTable
                    isExpired={isExpired}
                    products={products}
                    onRegister={registerLoan}
                    loanId={loanId}
                    currentNoteRate={currentNoteRate}
                    originalRate={interestRate}
                    commitmentType={commitmentType}
                    lockPeriod={lockPeriod}
                />
            ) : (
                <Typography
                    className={styles.noProductsFound}
                    variant="body2"
                >
                    No products found for the given lock period and commitment type.
                </Typography>
            )}

            {loanPricingResultDetail.ineligibleProducts?.length && (
                <Button
                    size="small"
                    className={styles.ineligibleProductsButton}
                    onClick={() => setShowIneligibleProducts(!showIneligibleProducts)}
                >
                    {`${showIneligibleProducts ? 'Hide' : 'View'}
                     ineligible products (${loanPricingResultDetail.ineligibleProducts.length})`}
                </Button>
            )}

            {showIneligibleProducts && (
                <PricingResultIneligibleProductsTable
                    ineligibleProducts={loanPricingResultDetail.ineligibleProducts}
                />
            )}
        </>
    );
}
